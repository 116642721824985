/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

export const onRouteUpdate = () => {

    /* menu 操作 */
    const menuButton = document.querySelectorAll("[class*='menuButton']");
    const menuBox = document.querySelectorAll("[class*='menuFrame']");
 
    let clickMenuButton = function(event) {
        if (!menuButton[0].classList.contains('clicked')) {
            menuButton[0].classList.add('clicked');
            menuBox[0].classList.add('visible');
        } else {
            disappearMenuBox();
        }
    }

    let disappearMenuBox =  function(event) {
        menuButton[0].classList.remove('clicked');
        menuBox[0].classList.remove('visible');
    }

    document.addEventListener('click', (e) => {
        if(e.target.closest("[class*='menuButton']")) {
            // do nothing
        } else if (e.target.closest("[class*='menuFrame']")) {
            // do nothing
        } else {
            disappearMenuBox();
        }
        
        if (e.target.closest("[class*='menuFrame'] a")) {
            disappearMenuBox();
        }
    })

    menuButton[0].addEventListener('click', clickMenuButton);

    /* observe 処理 */
    
    const options = {
        root: null, // 今回はビューポートをルート要素とする
        rootMargin: "-50% 0px", // ビューポートの中心を判定基準にする
        threshold: 0 // 閾値は0
    };
    const observer = new IntersectionObserver(doWhenIntersect, options);
    // それぞれのboxを監視する
    
    var observerFrame;
    
    /**
     * 交差したときに呼び出す関数
     * @param entries
     */
    function doWhenIntersect(entries) {
        // 交差検知をしたもののなかで、isIntersectingがtrueのDOMを色を変える関数に渡す
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                activateIndex(entry.target);
            }
        });
    }
    
    /**
     * 目次の色を変える関数
     * @param element
     */
    function activateIndex(element) {
        
    }
    
    function doWhenIntersectFrame(entries) {
        
    }

    function getUrlParam() {
        var q = window.location.search.split("?"),
            a,
            i,
            s,
            o;
        if (q.length > 1) {
            o = {};
            q = q[1];
            a = q.split("&");
            for (i = 0; i < a.length; i++) {
                s = a[i].split("=");
                s[0] = s[0].replace("%5B%5D", "");
                s[0] = s[0].replace("[]", "");
                if (!o[s[0]]) {
                    o[s[0]] = [s[1]];
                } else {
                    o[s[0]].push(s[1]);
                }
            }
        }
        return o;
    }
}
